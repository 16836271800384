<template lang="pug">
  div
    v-data-table.elevation-2(:headers="columns" :items="songs" :disable-pagination="true" :disable-sort="true" item-key="id" show-expand single-expand :expanded.sync="expanded")
      template(#item.songwriting="{ item }")
        .percentage(:class="{over: item.songwriting > 100, full: item.songwriting === 100, partial: item.songwriting > 0, none: !item.songwriting}")
          | {{ item.formatted.songwriting }}
      template(#item.mastering="{ item }")
        .percentage(:class="{over: item.mastering > 100, full: item.mastering === 100, partial: item.mastering > 0, none: !item.mastering}")
          | {{ item.formatted.mastering }}
      template(#item.publishing="{ item }")
        .percentage(:class="{over: item.publishing > 100, full: item.publishing === 100, partial: item.publishing > 0, none: !item.publishing}")
          | {{ item.formatted.publishing }}
      template(#item.full_royalties="{ item }")
        .royalties-status
          v-icon.full-royalties(v-show="item.full_royalties") mdi-check
          v-icon.not-full-royalties(v-show="!item.full_royalties") mdi-close

      template(#expanded-item="{ headers, item }")
        td.details(:colspan="headers.length")
          v-data-table.elevation-0(:headers="columnsDropdown" :items="people" :disable-pagination="true" :disable-sort="true" item-key="id")
            template(#item.songwriting="{ item }")
              .percentage
                | {{ item.formatted.songwriting }}
            template(#item.mastering="{ item }")
              .percentage
                | {{ item.formatted.mastering }}
            template(#item.publishing="{ item }")
              .percentage
                | {{ item.formatted.publishing }}

      template(v-slot:header.name="{ header }")
        | {{ header.text }}
        v-menu(:close-on-content-click="false" @input="hide($event, 'name')" v-model="filters.name.dropdown")
          template(v-slot:activator="{ on, attrs }")
            v-icon(v-bind="attrs" v-on="on" :class="{active: filters.name.value}") mdi-magnify
          .v-menu__content--filter
            v-subheader Song name
            v-text-field(label="Filter by name" dense v-model="filters.name.input")
            v-btn(color="primary" @click="filter('name')") Filter
            v-btn(color="primary" small text @click="reset('name')" v-show="filters.name.value") Reset
      template(v-slot:header.artist="{ header }")
        | {{ header.text }}
        v-menu.artists-filter(:close-on-content-click="false" @input="hide($event, 'artists')" v-model="filters.artists.dropdown")
          template(v-slot:activator="{ on, attrs }")
            v-icon(v-bind="attrs" v-on="on" :class="{active: filters.artists.value.length}") mdi-filter
          .v-menu__content--filter
            v-list(dense)
              v-subheader Artists
              v-autocomplete(:items="artists" item-text="name" item-value="id" v-model="filters.artists.input" multiple @input="filterByArtists")
                template(#selection="{ item, index }")
                  v-chip(v-if="index === 0")
                    span {{ item.name }}
                  span.grey--text.text-caption(v-if="index === 1")
                    | (+{{ filters.artists.input.length - 1 }} hidden)
            v-btn(color="primary" small text @click="reset('artists')" v-show="filters.artists.value.length") Reset
      template(v-slot:header.full_royalties="{ header }")
        .full-royalties-title
          | {{ header.text }}
          v-menu(:close-on-content-click="false" @input="hide($event, 'not_full')" v-model="filters.not_full.dropdown")
            template(v-slot:activator="{ on, attrs }")
              v-icon(v-bind="attrs" v-on="on" :class="{active: filters.not_full.value}") mdi-filter
            .v-menu__content--filter
              v-subheader Filter by royalty status
              v-checkbox(label="Songs without full royalties only" dense v-model="filters.not_full.input")
              v-btn(color="primary" @click="filter('not_full')") Filter
              v-btn(color="primary" small text @click="reset('not_full')" v-show="filters.not_full.value") Reset
    v-pagination(v-model="filters.page.value" circle  :length="songData.total_pages" total-visible="10")
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      filters: {
        name: {
          dropdown: false,
          value: null,
          input: null
        },
        artists: {
          dropdown: false,
          value: [],
          input: [],
        },
        not_full: {
          dropdown: false,
          value: false,
          input: false,
        },
        page: {
          value: 1
        }
      },
      shown: null,
      expanded: [],
      people: [],
      columns: [],
      columnsDropdown: [],
    };
  },
  computed: {
    ...mapState({
      songData: state => state.songs.fullSongs,
      songs: state => state.songs.fullSongs.items,
      artists: state => state.songs.artists,
    }),
  },



  methods:{
    filter(name){
      this.filters[name].value = this.filters[name].input;
      this.filters[name].dropdown = false;

      this.filters.page.value = 1;

      this.getSongs();
    },
    filterByArtists(){
      this.filters.artists.value = this.filters.artists.input;
      this.filters.page.value = 1;
      this.getSongs();
    },
    reset(name){
      if(typeof this.filters[name].value === 'object'){
        this.filters[name].input = [];
        this.filters[name].value = [];
      }else{
        this.filters[name].input = null;
        this.filters[name].value = null;
      }
      this.filters[name].dropdown = false;
      this.filters.page.value = 1;

      this.getSongs();
    },
    hide(shown, name){
      if(!shown){
        this.filters[name].input = this.filters[name].value;
      }
    },
    setupTable(){
      this.columns = [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Artist',
          value: 'artist',
        },
        {
          text: 'Songwriting',
          value: 'songwriting',
        },
        {
          text: 'Publishing',
          value: 'publishing',
        },
        {
          text: 'Master',
          value: 'mastering',
        },
        {
          text: 'Full royalties',
          value: 'full_royalties',
        },
        {
          text: 'Expand',
          value: 'data-table-expand',
        },
      ];
      this.columnsDropdown = [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Songwriting',
          value: 'songwriting',
        },
        {
          text: 'Publishing',
          value: 'publishing',
        },
        {
          text: 'Master',
          value: 'mastering',
        },
      ]
    },
    async getSongs(){
      return await this.$store.dispatch("songs/getFullSongs", this.filters);
    },
    async getPeopleForSong(){
      this.people = await this.$store.dispatch('songs/getPeopleForSong', this.shown);
    }
  },
  async mounted() {
    await this.getSongs();
    this.setupTable();
  },
  watch: {
    async expanded(value) {
      const item = value[0]
      this.shown = item.id;
      this.people = [];
      await this.getPeopleForSong();
    },
    'filters.page.value'() {
      this.getSongs();
    },
    $route: {
      handler: function(){
        this.getSongs();
      },
      immediate: true
    }
  }
};
</script>


<style lang="scss" scoped>
@import "../styles/variables";





::v-deep {

  .v-icon.selected-icon{
    position: absolute;
    right: 10px;
  }








  .v-data-table__expanded{

    &.v-data-table__expanded__row{
      background-color: rgba($primary, 0.075);
    }


    td.details{
      padding: 0px;

      .v-data-table{
        border-radius: 0;
        box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.25) !important;
      }

      .percentage {
        font-weight: normal;
        font-size: 17px;
      }
    }
  }

  .percentage{
    font-weight: bold;
    font-size: 17px;

    &.partial{
      color: $warning;
    }

    &.none{
      color: $gray;
    }

    &.full{
      color: $success;
    }

    &.over{
      color: $error;
      font-size: 19px;
      text-decoration: line-through;
    }
  }



  .royalties-status{
  }

  .full-royalties{
    color: $success;
  }

  .not-full-royalties{
    color: $error;
  }

  .v-data-table{
    .v-data-table-header{
      .v-icon{
        margin-left: 10px;
      }
    }

    .v-data-footer{
      display: none;
    }
  }
}
.full-royalties-title{
  width: 115px;
}

.v-menu__content{
  max-width: 300px;
}


</style>
