var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.columns,"items":_vm.songs,"disable-pagination":true,"disable-sort":true,"item-key":"id","show-expand":"","single-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.songwriting",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"percentage",class:{over: item.songwriting > 100, full: item.songwriting === 100, partial: item.songwriting > 0, none: !item.songwriting}},[_vm._v(_vm._s(item.formatted.songwriting))])]}},{key:"item.mastering",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"percentage",class:{over: item.mastering > 100, full: item.mastering === 100, partial: item.mastering > 0, none: !item.mastering}},[_vm._v(_vm._s(item.formatted.mastering))])]}},{key:"item.publishing",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"percentage",class:{over: item.publishing > 100, full: item.publishing === 100, partial: item.publishing > 0, none: !item.publishing}},[_vm._v(_vm._s(item.formatted.publishing))])]}},{key:"item.full_royalties",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"royalties-status"},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.full_royalties),expression:"item.full_royalties"}],staticClass:"full-royalties"},[_vm._v("mdi-check")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!item.full_royalties),expression:"!item.full_royalties"}],staticClass:"not-full-royalties"},[_vm._v("mdi-close")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"details",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.columnsDropdown,"items":_vm.people,"disable-pagination":true,"disable-sort":true,"item-key":"id"},scopedSlots:_vm._u([{key:"item.songwriting",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"percentage"},[_vm._v(_vm._s(item.formatted.songwriting))])]}},{key:"item.mastering",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"percentage"},[_vm._v(_vm._s(item.formatted.mastering))])]}},{key:"item.publishing",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"percentage"},[_vm._v(_vm._s(item.formatted.publishing))])]}}],null,true)})],1)]}},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_vm._v(_vm._s(header.text)),_c('v-menu',{attrs:{"close-on-content-click":false},on:{"input":function($event){return _vm.hide($event, 'name')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:{active: _vm.filters.name.value}},'v-icon',attrs,false),on),[_vm._v("mdi-magnify")])]}}],null,true),model:{value:(_vm.filters.name.dropdown),callback:function ($$v) {_vm.$set(_vm.filters.name, "dropdown", $$v)},expression:"filters.name.dropdown"}},[_c('div',{staticClass:"v-menu__content--filter"},[_c('v-subheader',[_vm._v("Song name")]),_c('v-text-field',{attrs:{"label":"Filter by name","dense":""},model:{value:(_vm.filters.name.input),callback:function ($$v) {_vm.$set(_vm.filters.name, "input", $$v)},expression:"filters.name.input"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.filter('name')}}},[_vm._v("Filter")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.filters.name.value),expression:"filters.name.value"}],attrs:{"color":"primary","small":"","text":""},on:{"click":function($event){return _vm.reset('name')}}},[_vm._v("Reset")])],1)])]}},{key:"header.artist",fn:function(ref){
var header = ref.header;
return [_vm._v(_vm._s(header.text)),_c('v-menu',{staticClass:"artists-filter",attrs:{"close-on-content-click":false},on:{"input":function($event){return _vm.hide($event, 'artists')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:{active: _vm.filters.artists.value.length}},'v-icon',attrs,false),on),[_vm._v("mdi-filter")])]}}],null,true),model:{value:(_vm.filters.artists.dropdown),callback:function ($$v) {_vm.$set(_vm.filters.artists, "dropdown", $$v)},expression:"filters.artists.dropdown"}},[_c('div',{staticClass:"v-menu__content--filter"},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Artists")]),_c('v-autocomplete',{attrs:{"items":_vm.artists,"item-text":"name","item-value":"id","multiple":""},on:{"input":_vm.filterByArtists},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v("(+"+_vm._s(_vm.filters.artists.input.length - 1)+" hidden)")]):_vm._e()]}}],null,true),model:{value:(_vm.filters.artists.input),callback:function ($$v) {_vm.$set(_vm.filters.artists, "input", $$v)},expression:"filters.artists.input"}})],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.filters.artists.value.length),expression:"filters.artists.value.length"}],attrs:{"color":"primary","small":"","text":""},on:{"click":function($event){return _vm.reset('artists')}}},[_vm._v("Reset")])],1)])]}},{key:"header.full_royalties",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"full-royalties-title"},[_vm._v(_vm._s(header.text)),_c('v-menu',{attrs:{"close-on-content-click":false},on:{"input":function($event){return _vm.hide($event, 'not_full')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:{active: _vm.filters.not_full.value}},'v-icon',attrs,false),on),[_vm._v("mdi-filter")])]}}],null,true),model:{value:(_vm.filters.not_full.dropdown),callback:function ($$v) {_vm.$set(_vm.filters.not_full, "dropdown", $$v)},expression:"filters.not_full.dropdown"}},[_c('div',{staticClass:"v-menu__content--filter"},[_c('v-subheader',[_vm._v("Filter by royalty status")]),_c('v-checkbox',{attrs:{"label":"Songs without full royalties only","dense":""},model:{value:(_vm.filters.not_full.input),callback:function ($$v) {_vm.$set(_vm.filters.not_full, "input", $$v)},expression:"filters.not_full.input"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.filter('not_full')}}},[_vm._v("Filter")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.filters.not_full.value),expression:"filters.not_full.value"}],attrs:{"color":"primary","small":"","text":""},on:{"click":function($event){return _vm.reset('not_full')}}},[_vm._v("Reset")])],1)])],1)]}}])}),_c('v-pagination',{attrs:{"circle":"","length":_vm.songData.total_pages,"total-visible":"10"},model:{value:(_vm.filters.page.value),callback:function ($$v) {_vm.$set(_vm.filters.page, "value", $$v)},expression:"filters.page.value"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }