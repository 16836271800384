<template lang="pug">
  .outgoing
    v-container
      PageTitle
      SongsTable
</template>

<script>
import SongsTable from "@/components/SongsTable";
import PageTitle from "../components/PageTitle";

export default {
  components: {
    PageTitle,
    SongsTable,
  },
};
</script>
